.edcucationInfoDiv {
  display: flex;
}

.edcucationInfoDiv > b {
  margin-right: 30px;
}

.educationDisclosurePurple {
  color: #7034c6;
}
