@media (min-width: 768px) {
  .eventsRow {
    padding: 60px 0;
  }
}

.eventDiv {
  display: flex;
  text-decoration: none !important;
  align-items: center;
  width: 100%;
  color: inherit;
  transition-duration: 0.4s;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .eventDiv {
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .eventDiv {
    flex-wrap: wrap;
  }
}

.eventDiv:hover {
  color: #7034c6;
  transition-duration: 0.4s;
}

.eventDateDiv {
  text-align: center;
}

@media (max-width: 768px) {
  .eventDateDiv {
    margin: auto;
  }
}

.eventMonth {
  color: #7034c6;
  margin: 0;
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
}

.eventDate {
  margin: 0;
  font-size: 60px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700 !important;
}

@media (min-width: 768px) {
  .eventDescriptionDiv {
    padding-left: 60px;
  }
}

.eventTime,
.eventTitle {
  margin: 0;
}

.eventTime {
  font-size: 20px;
  color: #a09f9f;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
}

.eventTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700 !important;
}

@media (min-width: 768px) {
  .eventImgDiv {
    margin-left: auto;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .eventImgDiv {
    margin: auto;
  }
}

.eventImg {
  width: auto;
  height: 300px;
}
