.aboutHeaderSection {
  background-color: #f5f5f6;
  padding: 0 0 90px 0;
}

@media (max-width: 768px) {
  .aboutHeaderSection {
    padding: 0 0 30px 0;
  }
}

@media (min-width: 768px) {
  .aboutHeaderDiv {
    padding-top: 60px !important;
  }
}

@media (max-width: 768px) {
  .aboutHeaderDiv {
    text-align: center;
  }
}

.aboutHeader {
  font-size: 42px;
  margin-bottom: 30px;
}

.aboutText {
  font-size: 18px;
}

/* About Main Section */

.aboutBioSection {
  background: repeating-linear-gradient(
    141.5deg,
    rgb(56, 56, 56) 0%,
    rgb(56, 56, 56) 27%,
    rgb(43, 43, 43) 27%,
    rgb(43, 43, 43) 92%
  );
  padding: 90px 0;
}

@media (max-width: 768px) {
  .aboutBioSection {
    padding: 60px 0 30px 0;
  }
}

.aboutBioDiv {
  color: #f5f5f6;
}

.aboutBioRowDiv {
  padding-top: 120px !important;
}

@media (max-width: 768px) {
  .aboutBioRowDiv {
    padding-top: 60px !important;
  }
}

@media (max-width: 768px) {
  .aboutBioRow {
    padding-bottom: 30px !important;
  }

  .aboutTeamDiv {
    display: inline-block;
  }

  .aboutTeamDiv p {
    text-align: left;
    margin-top: 5px;
  }
}

.aboutBioImg {
  width: auto;
  height: 200px;
}

.aboutBioLink {
  text-decoration: none !important;
  color: #7034c6;
}

.bioModalHeader {
  border: none !important;
  padding: 30px 30px 0 30px !important;
  background-color: #7034c6 !important;
  display: block !important;
  z-index: 1;
  color: #ffffff;
  line-height: normal;
}

.bioModalBody {
  padding: 0 !important;
  background-color: #7034c6;
  color: #ffffff;
}

.bioHR {
  background-color: #ffffff;
  height: 3px;
  border: none;
  opacity: 1;
}

.bioModalTitle {
  font-size: 20px;
  margin-top: -10px;
  display: block;
  font-size: 28px;
}

@media (max-width: 768px) {
  .bioModalTitle {
    font-size: 20px;
  }
}

.bioLinkedInImg {
  width: 45px;
  height: auto;
}

.aboutBioTextHeader {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  font-size: 34px;
}

/* font-family: "Source Sans Pro", sans-serif; */
/* font-family: "Source Sans Pro", sans-serif; */

.aboutCoverDiv {
  background: url("./img/CoverPhoto.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  align-items: center;
}

.aboutCoverText {
  padding: 30px;
}

@media (min-width: 768px) {
  .aboutCoverText {
    width: 50%;
    color: #ffffff;
    background-color: rgba(112, 52, 198, 0.75);
    padding: 30px;
  }
}

.aboutTeamDiv:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .aboutCoverDivDesktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .aboutCoverDivMobile {
    display: none;
  }
}

.aboutCoverImgMobile {
  width: 100%;
  height: auto;
}
