.generalCoverDiv {
  /* background: url("./img/cover.png"); */
  background: url("./img/general_cover.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  height: auto;
}

/* @media(max-height: 768px) {
  .logo {
    width: 75px;
    height: auto;
  }
} */

.generalCoverWhiteDiv {
  background-color: rgba(256, 256, 256, 0.75);
  /* background: url("./img/general_cover.png");
  background-position: bottom; */
  /* width: 75%; */
  height: auto;
  display: flex;
  /* padding-right: 25% !important; */
  margin-bottom: -1px;
  /* -webkit-clip-path: polygon(
    0% 100%,
    100% 100%,
    70% 100%,
    calc(100% - 600px) 0%,
    0% 0%
  );
  clip-path: polygon(
    0% 100%,
    100% 100%,
    70% 100%,
    calc(100% - 600px) 0%,
    0% 0%
  ); */
}

/* @media (max-width: 1440px) {
  .generalCoverWhiteDiv {
    -webkit-clip-path: polygon(
      0% 100%,
      100% 100%,
      80% 100%,
      calc(100% - 350px) 0%,
      0% 0%
    );
    clip-path: polygon(
      0% 100%,
      100% 100%,
      80% 100%,
      calc(100% - 350px) 0%,
      0% 0%
    );
  }
} */

/* Alternative Cover Section */

.generalCoverBackgroundContainer {
  min-height: 200px;
}

.generalCoverBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.generalCoverBackgroundDiv {
  background: url("./img/cover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.generalCoverLightDiv {
  background: linear-gradient(
    57.5deg,
    rgba(256, 256, 256, 1) 57.5%,
    rgba(0, 0, 0, 0.5) 57.7%
  );
  z-index: 9;
}

/* End Alternative Cover Section */

.generalCoverTextDiv {
  margin: 200px 0 auto 0;
  /* padding-bottom: 90px; */
}

.generalCoverHeader {
  font-size: 50px;
  margin: 0;
  text-align: center;
}

.generalCoverText {
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

@media (max-width: 768px) {
  .generalCoverTextDiv {
    padding: 60px 30px;
    margin: 0;
  }
  .generalCoverHeader {
    font-size: 24px;
    text-align: center;
  }

  .generalCoverText {
    font-size: 18px;
    text-align: center;
    margin: 0;
  }
}

/* Mobile Section */

@media (max-width: 768px) {
  .generalCoverSectionDesktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .generalCoverSectionMobile {
    display: none;
  }
}

/* .generalCoverImgDivMobile {
  background: url("./img/cover_mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
} */
