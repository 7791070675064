.footerSection {
  padding: 90px 0 0 0;
}

@media (max-width: 768px) {
  .footerSection {
    padding: 60px 0 0 0;
  }
}

.mainFooterDiv {
  text-align: center;
}

.footerLogo {
  width: 150px;
  height: auto;
}

.verticalLine {
  height: 75px;
  width: 2px;
  background-color: #7034c6;
  margin: 30px auto;
}

.footerGetInTouch {
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 2px;
}

.footerContactDiv {
  font-size: 24px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .footerContactDiv {
    font-size: 28px;
    overflow-wrap: break-word;
  }
}

.footerContactDiv > p {
  margin: 0;
  line-height: 32px;
}

/* Footer Social */

.footerSocialDiv {
  margin-top: 60px;
}

.footerSocialLink {
  margin: 0 20px;
}

.footerSocialImg {
  width: 30px;
  height: auto;
}

.footerTextDiv {
  margin-top: 120px;
  margin-bottom: 60px;
}

.footerText {
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
}

/* Copyright section */

.footerCopyrightDiv {
  background-color: #ededed;
  padding-top: 60px;
  padding-bottom: 60px;
}

.footerCopyrightTextDiv {
  display: flex;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
}

/* @media (max-width: 768px) {
  .footerCopyrightTextLeft,
  .footerCopyrightTextRight {
    text-align: center;
  }
} */

.footerCopyrightTextDiv > p {
  margin: 0;
}

.footerCopyrightTextRight {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 768px) {
  .footerCopyrightTextRight {
    margin-right: 0;
    margin-left: auto;
  }
}

.footerCopyrightTextRight:hover {
  color: #7034c6;
}

.footerDisclosureLink {
  color: #7034c6;
  text-decoration: none;
  font-weight: 600;
}
