#menu {
  /* padding-top: 60px;
  padding-bottom: 60px; */
  z-index: 999;
  height: auto;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 140px 0 140px;
}

@media (max-width: 768px) {
  #menu {
    display: none;
  }
}

#menu .navbar-nav .navLinks {
  color: #ffffff !important;
  border: 2px solid #7034c6;
  margin-left: 30px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  background-color: #7034c6;
  width: auto;
  min-width: 125px;
  text-align: center;
  transition-duration: 0.4s;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.navLinks.nav-link {
  align-items: center;
  justify-content: center;
}

.closeBtnDiv {
  display: flex;
  align-items: center;
  height: 100%;
}

.openMenu .navbar-nav .navLinks {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  margin-left: 30px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  background-color: #7034c6;
  width: 125px;
  text-align: center;
  transition-duration: 0.4s;
}

#menu .navbar-nav .navLinks:hover,
#openMenu .navbar-nav .navLinks:hover {
  color: #7034c6 !important;
  border: 2px solid #7034c6;
  background-color: #ffffff;
  transition-duration: 0.4s;
}

.logo {
  width: 120px;
  height: auto;
  padding: 15px;
}

@media (max-width: 768px) {
  .logo {
    width: 75px;
  }
}

.navMenu {
  width: 30px;
  height: auto;
  margin-left: 30px;
}

.menuBarIcon {
  margin-left: 30px;
}

.menuBar1 {
  height: 3px;
  width: 40px;
  background-color: #000000;
  margin-bottom: 7.5px;
  margin-right: 0;
  margin-left: auto;
}

.menuBar2 {
  height: 3px;
  width: 30px;
  background-color: #000000;
  margin-bottom: 7.5px;
  margin-right: 0;
  margin-left: auto;
}

.menuBar3 {
  height: 3px;
  width: 20px;
  background-color: #000000;
  margin-right: 0;
  margin-left: auto;
}

.closeBtn {
  width: 30px;
  height: auto;
  margin-left: 30px;
  display: block;
}

/* Open Menu Section */

#openMenu {
  z-index: 1030;
  padding-top: 60px;
  padding-bottom: 60px;
}

.openMenu {
  padding: 0 !important;
}

.openMenuMainDiv {
  overflow-y: auto !important;
  display: contents;
}

.openMenuLinksDiv {
  z-index: 10;
  overflow-y: auto;
}

/* .menuBackgroundContainer {
  position: relative;
} */

@media (min-width: 768px) {
  .menuBackgroundContainer {
    min-height: 100vh;
  }
}

@media (max-width: 768px) {
  .menuBackgroundContainer {
    display: none;
  }
}

.menuBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.menuOpenBackgroundDiv {
  background: url("../Home/img/cover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.menuOpenLightDiv {
  background: linear-gradient(
    57.5deg,
    rgba(120, 48, 219, 0.7) 57.5%,
    rgba(142, 72, 240, 0.7) 57.7%
  );
  z-index: 9;
}

.menuOpenDarkDiv {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
}

.openMenuLink {
  color: #ffffff;
  text-decoration: none !important;
  margin: 0 0 10px 0;
  display: block;
  font-size: 32px;
  transition-duration: 0.4s;
  letter-spacing: 2px;
}

@media (max-width: 1366px) {
  .openMenuLink {
    font-size: 24px;
  }
}

.openMenuLink:hover {
  color: rgba(256, 256, 256, 0.2);
}

.openMenuSubLink {
  margin-top: 15px !important;
  font-size: 18px !important;
}

@media (max-width: 768px) {
  .openMenuLink {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .openMenuLinkMobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .openMenuBottomRow {
    z-index: 10;
    position: absolute;
    bottom: 60px;
    width: 100%;
    margin: 60px 0 0 0 !important;
  }
}

@media (max-height: 1366px) {
  .openMenuBottomRow {
    margin-top: 2.5% !important;
  }
}

@media (max-width: 768px) {
  .openMenuBottomRow {
    z-index: 10;
    margin: 0 !important;
  }
}

.openMenuBottomCol {
  padding: 0 !important;
}

@media (max-height: 768px) {
  .menuBackgroundContainer {
    min-height: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 60px;
  }
  /* .openMenuBottomRow {
    position: absolute;
    bottom: 60;
  } */
}

.openMenuCopyright {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}

.openMenuMyriannLink {
  text-align: right;
  display: block;
  text-decoration: none !important;
  color: #ffffff;
  font-size: 18px;
  margin: 0;
}

@media (max-width: 768px) {
  .openMenuCopyright,
  .openMenuMyriannLink {
    color: #000000;
  }
  .openMenuBottomRow {
    border-top: 1px solid #000000 !important;
  }
}

.openMenuMyriannLink:hover {
  color: rgba(256, 256, 256, 0.2);
}

.openMenuSocialLink {
  margin-right: 30px;
  width: 30px;
  height: auto;
}

.openMenuSocialImg {
  width: 45px;
}

@media (max-width: 768px) {
  .openMenuBottomRow {
    border-top: 1px solid #ffffff;
    padding-top: 30px;
  }
  .openMenuSocial {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .openMenuCopyright,
  .openMenuMyriannLink {
    text-align: left !important;
    margin-bottom: 10px;
  }
}

/* Mobile Menu */

#mobileMenu {
  background-color: #f5f5f6 !important;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #mobileMenu {
    display: none;
  }
}

.navbar-toggler {
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.menuSpan {
  width: 35px;
  height: 3px;
  background-color: #7034c6;
  margin: 3px 0;
}

#mobileMenu a.nav-link {
  font-size: 24px;
  color: #7034c6;
  margin-top: 15px;
  text-align: center;
}

#mobileMenu .navbar-nav {
  padding: 15px 0 30px 0;
}

/* #mobileMenu .navbar-collapse {
  height: 100vh;
} */

.offeringsMenuButton {
  text-decoration: none !important;
  padding: 30px 15px;
  background-color: #ffffff;
  color: #000000;
}

.offeringsMenuButton:hover {
  background-color: #000000;
  color: #ffffff;
}

.offeringsMobileLink {
  background-color: #000000 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.offeringsMobileLink:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.offeringsMobileLinkPurple {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.offeringsMobileLinkPurple:hover {
  background-color: #7034c6 !important;
  color: #000000 !important;
}
