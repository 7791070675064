.howDisclaimer {
  padding: 60px 0 30px 0;
}

@media (max-width: 768px) {
  .howDisclaimer {
    padding: 0 0 30px 0;
  }
}

.howHeader {
  padding-bottom: 30px;
  font-size: 32px;
}

@media (max-width: 768px) {
  .howHeader {
    text-align: center;
  }
}

.howStep {
  align-items: center;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .howStep {
    display: -webkit-inline-box;
  }
}

.howStepNumber {
  display: flex;
  background-color: #7034c6;
  width: 60px;
  height: 60px;
  color: #ffffff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.howStepTextHeader {
  font-size: 20px;
  font-weight: bold;
}

.howStepText {
  margin: auto 0 auto 15px;
}

@media (max-width: 768px) {
  .howStepNumber {
    margin: 0 auto 15px auto;
  }

  .howStepText {
    border-bottom: 1px solid #7034c6;
    padding-bottom: 15px;
  }
}

.howButton {
  width: 300px !important;
  margin-top: 30px;
}

.howList > li {
  margin-top: 10px;
}

.howVideoDiv {
  text-align: center;
  padding: 0 0 30px 0;
  margin: auto;
}

@media (min-width: 768px) {
  .howVideoDiv {
    width: 600px;
    padding: 60px 0 0 0;
  }
}
