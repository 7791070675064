.modalBackdrop {
  background-color: #000000 !important;
  opacity: 0.3 !important;
}

.signupModalHeader {
  border: none !important;
  padding: 30px !important;
  background-color: #ffffff !important;
  display: block !important;
  z-index: 1;
}

.signupModalBody {
  padding: 0 !important;
  background-color: #ffffff;
}

.signupCheckboxHeader {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
}

.signupCheckboxText {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
}

@media (min-width: 768px) {
  .signupButton {
    margin-top: 60px;
  }
}

.signupChangeText {
  color: #545454;
  font-size: 14px;
}

.signupChangeLink {
  color: #7034c6;
  text-decoration: none !important;
}

.identificationUpload {
  font-size: 14px;
}

/* Login Section */

#loginModalDialog {
  /* margin-top: 12% !important; */
  /* top: 20% !important; */
  transform: translate(0, 0) !important;
  max-width: 50%;
}

@media (max-width: 768px) {
  #loginModalDialog {
    max-width: 100%;
  }
}

.loginModalTitle {
  text-align: center;
  padding: 30px 0 0 0;
}

.loginModalCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.loginForm {
  padding: 30px 60px !important;
}

.loginButton {
  margin-top: 15px;
}

.signupChangeTextRight {
  text-align: right;
}

@media (max-width: 768px) {
  .signupChangeTextRight {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .inputLoginCol {
    padding-bottom: 30px;
  }
}

.messageHeader {
    text-align: center;
    color: #7034c6;
    margin-bottom: 0;
}

.errorText {
  color: red;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}