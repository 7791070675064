.faqSection {
  background: repeating-linear-gradient(
    /* 130deg, */ 141.5deg,
    rgb(265, 265, 265) 7%,
    rgb(265, 265, 265) 45%,
    rgb(245, 245, 246) 45%,
    rgb(245, 245, 246) 92%
  );
}

.faqDiv {
  padding-bottom: 60px !important;
}

.faqNavbarDiv {
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
}

.faqNavlink {
  color: rgba(43, 43, 43, 0.86);
  text-decoration: none !important;
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin-right: 80px;
}

@media (max-width: 768px) {
  .faqNavlink {
    margin-right: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    font-size: 24px;
  }
  .faqNavlinkActive {
    margin-bottom: 20px;
  }
}

.faqNavlink:hover {
  color: rgba(43, 43, 43, 1);
  font-weight: 700 !important;
  border-bottom: 2px solid #7034c6;
  padding-bottom: 10px;
}

.faqNavlinkActive {
  color: rgba(43, 43, 43, 1);
  font-weight: 700 !important;
  border-bottom: 2px solid #7034c6;
  padding-bottom: 10px;
}

/* Accordian Section */

.faqTextDiv {
  padding-top: 60px;
}

.faqText {
  font-size: 32px;
}

@media (max-width: 768px) {
  .faqText {
    font-size: 24px;
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../global/img/accordion_open.svg") !important;
  transform: rotate(0) !important;
}

.accordion-button::after {
  width: 4rem !important;
  height: 2.5rem !important;
  background-image: url("../../global/img/accordion_closed.svg") !important;
  background-size: 40px !important;
}

.accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
  z-index: 3;
  border: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-item {
  background-color: #fafafa !important;
  border: 1px solid #dbdbdb !important;
  margin-bottom: 20px !important;
}

.accordion-collapse {
  background-color: #ffffff !important;
}

.accordionHeader {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 700 !important;
  font-size: 28px !important;
}

.accordianAskerTitle {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.accordianAskerTitleSpan {
  font-weight: 700 !important;
  color: #7034c6;
}

@media (max-width: 768px) {
  .accordion-button {
    padding-left: 15px !important;
    padding-right: 0 !important;
  }

  .accordionHeader {
    font-size: 24px !important;
  }
}

.accordion-body {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.accordionSubHeader {
  font-size: 42px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}
