.coverSection {
  /* position: absolute;
  background: linear-gradient(
    159.06deg,
    rgba(255, 255, 255, 0.83) 8.27%,
    rgba(255, 255, 255, 0) 82.38%
  ); */
  /* mix-blend-mode: screen; */
  /* height: 100vh; */
}

@media (min-width: 768px) {
  .coverSectionMobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .coverSectionDesktop {
    display: none;
  }
}

.coverDiv {
  background: url("./img/cover.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: 50% 0%;
}

.coverWhiteDiv {
  background-color: #ffffff;
  width: 65%;
  height: 100vh;
  display: flex;
  padding-right: 25% !important;
  -webkit-clip-path: polygon(
    0% 100%,
    100% 100%,
    100% 100%,
    calc(100% - 450px) 0%,
    0% 0%
  );
  clip-path: polygon(
    0% 100%,
    100% 100%,
    100% 100%,
    calc(100% - 450px) 0%,
    0% 0%
  );
}

@media (max-width: 1440px) {
  .coverWhiteDiv {
    width: 72.5%;
    -webkit-clip-path: polygon(
      0% 100%,
      100% 100%,
      100% 100%,
      calc(100% - 325px) 0%,
      0% 0%
    );
    clip-path: polygon(
      0% 100%,
      100% 100%,
      100% 100%,
      calc(100% - 325px) 0%,
      0% 0%
    );
  }
}

.coverTextDiv {
  margin: auto 0;
  /* padding: 0 60px; */
}

.coverText {
  font-size: 50px;
}

.homeCoverHeader {
  font-size: 32px;
  /* letter-spacing: 2px; */
  margin-bottom: 15px;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900 !important;
  line-height: 40px;
}

@media (max-width: 768px) {
  .homeCoverHeaderMobile {
    text-align: center;
  }
}

.homeCoverText {
  font-size: 20px;
}

@media (max-width: 768px) {
  .coverTextDiv {
    padding: 60px 30px 0 30px;
  }
  .coverText,
  .homeCoverText {
    font-size: 18px;
    text-align: center;
  }
}

.coverButtonDiv {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .coverButtonDiv {
    margin-top: 30px;
    text-align: center;
  }
}

.coverButton {
  display: block !important;
  width: 350px !important;
}

@media (max-width: 768px) {
  .coverButton {
    width: 100% !important;
  }
}

.coverButton1 {
  background-color: #7034c6 !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  /* padding: 10px 20px !important; */
  font-size: 12px !important;
}

.coverButton1:hover {
  background-color: transparent !important;
  color: #7034c6 !important;
}

.coverButton2 {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
  /* padding: 10px 20px !important; */
  margin-top: 15px;
  /* margin-left: 30px; */
  font-size: 12px !important;
}

.coverButton2:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.coverButton3 {
  background-color: transparent !important;
  border: 1px solid #7034c6 !important;
  color: #7034c6 !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  margin-top: 15px;
  /* margin-left: 30px; */
  font-size: 12px !important;
}

.coverButton2:hover {
  background-color: #7034c6 !important;
  color: #ffffff !important;
}

@media (max-width: 768px) {
  .coverButton {
    width: 80%;
  }
  .coverButton2 {
    margin-left: 0;
    margin-top: 15px;
  }
  .coverButton3 {
    margin-left: 0;
    margin-top: 15px;
  }
}

.coverHowItWorksLink {
  text-decoration: none !important;
  color: #7034c6;
  margin-top: 15px;
  display: block;
}

.playImg {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

/* Current Opportunities */

.currentOpSection {
  padding: 90px 0;
  background: repeating-linear-gradient(
    130deg,
    rgb(43, 43, 43) 7%,
    rgb(43, 43, 43) 45%,
    rgb(56, 56, 56) 45%,
    rgb(56, 56, 56) 92%
  );
  color: #ffffff;
}

@media (max-width: 768px) {
  .currentOpSection {
    padding: 60px 30px;
  }
}

.currentOpHeader {
  font-size: 40px;
  text-align: center;
  /* letter-spacing: 2px; */
  margin-bottom: 90px;
}

/* Seen On */

.seenOnSection {
  padding-top: 60px;
  padding-bottom: 150px;
  background-color: #ededed;
}

.seenOnHeader {
  font-size: 40px;
  text-align: center;
  /* letter-spacing: 2px; */
  margin-bottom: 90px;
}

.seen-on-carousel-container {
  width: 100%;
}

.seen-on-carousel-container .react-multi-carousel-track {
  display: flex;
  align-items: center;
}

/* OLD */
/* .seenOnAdDiv {
  background-color: #ffffff;
  width: 300px;
  margin: auto;
} */

/* NEW */
.seenOnAdDiv {
  width: 300px;
  margin: auto;
}

@media (max-width: 768px) {
  .seenOnAdDiv {
    text-align: center;
  }
}

.seenOnTextDiv {
  padding: 20px;
}

.seenOnImg {
  width: 100%;
  height: 250px;
  margin: auto;
}

/* OLD */
.seenOnAdImg {
  height: 30px;
  width: auto;
}

/* NEW */
.seenOnAdImg {
  height: auto;
  width: 100%;
}

.seenOnAdImgReuters {
  height: auto;
  width: 200px;
}

.seenOnAdImgCnn {
  height: auto;
  width: 100px;
}

.seenOnAdImgSky {
  height: auto;
  width: 200px;
}

.seenOnAdImgBbc {
  height: auto;
  width: 150px;
}

/* Carousel */

.carousel-container {
  width: 100%;
}

.arrowBtnBlackDiv {
  bottom: -70px;
  right: 3.1%;
  position: absolute;
  margin-top: 60px;
}

.arrowBtnBlack {
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  border-radius: 0 !important;
  padding: 5px 15px 7.5px 15px !important;
  transition-duration: 0.4s;
}

.arrowBtnBlack:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border: 1px solid #000000 !important;
  border-radius: 0 !important;
  transition-duration: 0.4s;
}

.arrowImg {
  width: 30px;
  height: auto;
}

/* Testimonial Section */

.testimonialSection {
  padding: 60px 0 120px 0;
  /* background: repeating-linear-gradient(
    50deg,
    rgb(56, 56, 56),
    rgb(56, 56, 56) 45%,
    rgb(43, 43, 43) 45%,
    rgb(43, 43, 43) 92%
  );
  color: #ffffff; */
  background: repeating-linear-gradient(
    /* 
  130deg, */ 50deg,
    rgb(245, 245, 246),
    rgb(245, 245, 246) 45%,
    rgb(265, 265, 265) 45%,
    rgb(265, 265, 265) 92%
  );
}

@media (max-width: 768px) {
  .testimonialSection {
    padding: 60px 0;
  }
}

.generalBlackHeader {
  font-size: 40px;
  text-align: center;
  /* letter-spacing: 2px; */
  margin-bottom: 60px;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
}

.generalWhiteHeader {
  font-size: 40px;
  text-align: center;
  /* letter-spacing: 2px; */
  margin-bottom: 60px;
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
}

.generalSubHeader {
  text-align: center;
  font-size: 36px;
  font-weight: 900;
}

@media (max-width: 768px) {
  .generalBlackHeader,
  .generalWhiteHeader {
    font-size: 30px;
    margin-bottom: 30px;
    padding: 0 30px 30px;
  }

  .generalSubHeader {
    font-size: 18px;
  }
}

/* .testimonialHeader {
  font-size: 40px;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 90px;
} */

.arrowBtnWhiteDiv {
  bottom: -60px;
  right: 0;
  position: absolute;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .arrowBtnWhiteDiv {
    right: 30px;
  }
}

.testimonialSlideCounter {
  position: absolute;
  left: 0;
  bottom: -75px;
  font-size: 36px;
}

@media (max-width: 768px) {
  .testimonialSlideCounter {
    left: 30px;
  }
}

.arrowBtnWhite {
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  /* border: 1px solid #ffffff !important; */
  border-radius: 0 !important;
  padding: 5px 15px 7.5px 15px !important;
  transition-duration: 0.4s;
  box-shadow: none !important;
}

.arrowBtnWhite:hover {
  background-color: rgba(256, 256, 256, 0.2) !important;
  border: 1px solid #000000 !important;
  /* border: 1px solid #ffffff !important; */
  border-radius: 0 !important;
  transition-duration: 0.4s;
}

.testimonialDiv {
  margin: auto;
  text-align: center;
}

@media (min-width: 768px) {
  .testimonialDiv {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .testimonialDiv {
    padding: 0 30px;
    text-align: left;
  }
}

.testimonialLogo {
  width: 200px;
  height: auto;
  margin-bottom: 60px;
}

.testimonialText {
  font-size: 24px;
}

@media (max-width: 768px) {
  .testimonialText {
    font-size: 18px;
  }
}

.testimonialHrLine {
  width: 75px;
  margin: 45px auto 25px auto;
  background-color: rgba(256, 256, 256, 0.5);
}

.testimonialAuthor {
  color: rgba(256, 256, 256, 0.5);
}

.testimonialDisclaimerDiv {
  font-size: 14px;
}

@media (min-width: 768px) {
  .testimonialDisclaimerDiv {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .testimonialDisclaimerDiv {
    padding: 60px 30px 0 30px;
  }
}

.testimonialDisclaimerText {
  color: #7034c6;
  margin: 0;
  font-weight: 600;
}

.custom-dot-list-style {
  position: inherit !important;
  margin-bottom: 60px !important;
}

/* Updates Section */

.updatesSection {
  padding: 120px 0;
  background-color: #ededed;
}

@media (max-width: 768px) {
  .updatesSection {
    padding: 120px 30px;
  }
}

.updatesDiv {
  margin: auto;
}

@media (min-width: 1440px) {
  .updatesDiv {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .updatesDiv {
    width: 60%;
  }
}

.updatesHeader {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  /* letter-spacing: 2px; */
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
}

.updateInputDiv {
  display: flex;
  flex-direction: row;
  /* This bit draws the box around it */
  border: 1px solid #d3d3d3;
  /* I've used padding so you can see the edges of the elements. */
  padding: 2px;
  margin-top: 15px;
}

.updateInputField {
  /* Tell the input to use all the available space */
  flex-grow: 2;
  /* And hide the input's outline, so the form looks like the outline */
  border: none;
  box-shadow: none;
  width: 100%;
  border-radius: 0;
  padding: 0 15px;
  height: 50px;
  font-size: 14px;
}

.updateInputField:focus {
  outline: none;
}

.updateInputField::placeholder {
  font-size: 16px;
}

.updateInputButton {
  background-color: #7034c6 !important;
  border-radius: 0 !important;
  border: 1px solid #7034c6 !important;
  padding: 0 !important;
  width: 200px;
  outline: none !important;
  font-size: 20px;
  color: #ffffff;
  /* margin-left: -200px !important; */
}

.updateInputButton:hover {
  background-color: transparent !important;
  color: #7034c6 !important;
}

.updateInputButton:disabled {
  cursor: not-allowed;
}

/* Mobile Section */

.coverImgDiv {
  background: url("./img/cover_mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
}

.homeDisclaimerText {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

@media (min-width: 768px) {
  .homeDisclaimerText {
    position: absolute;
    bottom: 10px;
    width: 45%;
  }
}

/* .homeDisclaimerText {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding-top: 15px;
} */

@media (max-width: 768px) {
  .homeDisclaimerText {
    font-size: 14px;
    margin-top: 30px;
  }
}

/* Category Section */

.categoryMainDiv {
  padding-top: 60px;
}

.offeringsBackButton {
  font-size: 28px;
  color: #7034c6;
  text-decoration: none !important;
  display: block;
  margin-bottom: 30px;
  border: none !important;
  background: none !important;
  text-align: left;
}

.categoryTileCol {
  text-align: center;
  margin-bottom: 30px;
}

.categoryTile {
  display: grid;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 300px;
  height: 300px;
  padding: 30px;
  margin: auto;
  text-decoration: none !important;
  transition-duration: 0.4s;
}

.categoryTileHotels {
  background-color: #7034c6;
  border: 1px solid #7034c6;
}

.categoryTileDaycares {
  background-color: #5b1d35;
  border: 1px solid #5b1d35;
}

.categoryTileLand {
  background-color: #008037;
  border: 1px solid #008037;
}

.categoryTileMultiFamily {
  background-color: #1a1319;
  border: 1px solid #1a1319;
}

.categoryTileOffice {
  background-color: #a9863e;
  border: 1px solid #a9863e;
}

.categoryTileRetail {
  background-color: #b0acaf;
  border: 1px solid #b0acaf;
}

.categoryTileSeniorLiving {
  background-color: #c85103;
  border: 1px solid #c85103;
}

.categoryTileStudentHousing {
  background-color: #1b6e7e;
  border: 1px solid #1b6e7e;
}

.categoryTile:hover {
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.4s;
}

.categoryTileIcon {
  width: 75%;
  height: auto;
  margin: auto;
}

.categoryTileText {
  margin: auto;
}

.categoryTileLabel {
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
}

/* Cards Section */

.offeringsDiv {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
  background-color: #f5f5f6;
}

.offeringsCard {
  margin-bottom: 30px;
}

.offeringsCard:hover {
  cursor: pointer;
}

.offeringsCardImg {
  width: 100%;
  height: auto;
}

.offeringsCardImgOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Hide for now */
  display: none;
}

.offeringsCardTitle {
  font-size: 28px;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.offeringsCardLocation {
  margin-bottom: 20px;
  font-size: 14px;
}

.offeringsCardDesc {
  font-size: 20px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .offeringsCardTitle {
    font-size: 24px;
    line-height: 24px;
  }
  .offeringsCardLocation {
    margin-bottom: 10px;
  }
  .offeringsCardDesc {
    padding-bottom: 0px;
    font-size: 16px;
  }
}

.offeringsCardLearnMore {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #7034c6 !important;
  color: #7034c6 !important;
  padding: 7.5px 30px !important;
  font-weight: 600 !important;
}

.offeringsCardLearnMore:hover {
  background-color: #7034c6 !important;
  color: #ffffff !important;
}

/* Progress Bar Section */

.dealCardProgressBarDiv {
  position: absolute;
  bottom: 0;
  display: flex;
  color: #ffffff;
  z-index: 9;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 5px;
}

.dealCardProgressBarLabel {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 0;
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

@media (max-width: 768px) {
  .dealCardProgressBarDiv {
    padding: 5px;
  }
  .dealCardProgressBarLabel {
    font-size: 20px;
    line-height: 20px;
  }
}

.dealCardProgressBarSubLabel {
  font-size: 12px;
  margin-top: 0px;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  line-height: 12px;
}

.dealCardProgressBarLabelLeft {
  padding-right: 20px;
}

@media (max-width: 768px) {
  .dealCardProgressBarLabelLeft {
    padding-left: 20px;
  }
}

.dealCardProgressBarLabelRight {
  padding-left: 20px;
}

.dealCardProgressBarBorder {
  width: 60%;
  height: 10px;
  background-color: #d6d6d6;
  border-radius: 50px;
}

.dealCardProgressBar {
  /* width: 60%; */
  height: 10px;
  background-color: #7034c6;
  border-radius: 50px;
}

/* Deal Card Styles */

.subDealDiv {
  /* width: 350px; */
  margin: auto;
}

.subDealRow {
  margin-bottom: 30px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.subDealCol:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .subDealCol {
    margin-bottom: 60px;
  }
}

.subDealImgDiv {
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 300px;
}

@media (max-width: 768px) {
  .subDealImgDiv {
    height: 300px;
  }
}

.dealRegType {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding: 5px 15px;
  font-size: 12px;
  color: #ffffff;
}

.dealCardStatus {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  background-color: #d02a48;
  padding: 5px 15px;
  font-size: 10px;
  /* letter-spacing: 2px; */
  color: #ffffff;
}

.dealCardStatusFunded {
  background-color: #7034c6 !important;
}

.subDealImg {
  width: 100%;
  height: 400px;
}

.subDealTitle {
  margin-top: 15px;
  font-size: 32px;
}

@media (max-width: 768px) {
  .subDealImg {
    width: 100%;
    height: auto;
  }

  .subDealTitle {
    font-size: 24px;
  }
}

.subDealType {
  font-size: 14px;
}

.subDealDesc {
  font-size: 14px;
  color: rgba(256, 256, 256, 0.8);
}

.subDealLink {
  text-decoration: none;
  color: #ffffff;
}

.subDealLink:hover {
  color: #7034c6;
}
