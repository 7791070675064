.educationMainDiv {
  padding-top: 60px;
}

.educationTileCol {
  text-align: center;
  margin-bottom: 30px;
}

.educationTileDiv {
  display: grid;
  background-color: #7034c6;
  border: 1px solid #7034c6;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 300px;
  height: 300px;
  padding: 30px;
  margin: auto;
  transition-duration: 0.4s;
}

.educationTileDiv:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #7034c6;
  transition-duration: 0.4s;
}

.educationTileText {
  margin: auto;
}

.educationHyperLink {
  color: #ffffff;
}
