#investModalDialog {
  position: fixed !important;
  margin: auto !important;
  width: 50vw !important;
  max-width: 50vw !important;
  height: 100% !important;
  right: 0px !important;
  transform: none !important;
}

@media (max-width: 768px) {
  #investModalDialog {
    width: 100% !important;
    max-width: 100% !important;
  }

  .invest-now-modal > .modal-dialog {
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

.invest-now-modal > .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  width: 50vw !important;
  max-width: 50vw !important;
  height: 100% !important;
  right: 0px !important;
  transform: none !important;
}

.investModalContent {
  height: 100%;
  overflow-y: auto;
}

.modalClose {
  width: 30px;
  height: auto;
}

.modalHeader {
  border: none !important;
  padding: 30px !important;
  background-color: #ffffff !important;
  display: block !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.investModalHeaderSection {
  display: flex;
  align-items: center;
}

.modalCloseBtn {
  margin-left: auto;
}

@media (min-width: 768px) {
  .modalCloseBtn {
    /* flex-grow: 1; */
    /* text-align: right !important; */
    position: absolute;
    right: 0;
    top: 0;
  }
}

.modalBody {
  padding: 60px 0 0 0 !important;
  background-color: #ffffff;
}

.investModalTitleSection {
  background-color: #ffffff;
  padding: 60px 0 30px 0;
}

.investModalTitle {
  font-size: 40px !important;
}

@media (max-width: 768px) {
  .investModalTitle {
    font-size: 24px !important;
  }
}

@media (min-width: 1368px) {
  .investModalTitleDiv {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between */
  }
}

@media (max-width: 768px) {
  .investModalTitleDiv {
    flex-wrap: wrap;
  }
}

.investModalImg {
  width: 40%;
  height: auto;
}

@media (max-width: 1366px) {
  .investModalTitleTextDiv {
    padding-top: 30px !important;
  }
}

@media (max-width: 768px) {
  .investModalImg {
    width: 100%;
  }
}

@media (min-width: 1368px) {
  .investModalTitleTextDiv {
    padding: 0 30px;
    flex-grow: 1;
  }
}

.investModalTitleTextHeader {
  font-size: 28px;
  margin-bottom: 0;
}

.investModalTitleTextLocation {
  font-size: 14px;
}

.investModalTitleTextDesc {
  font-size: 16px;
  margin-top: 15px;
}

/* Progress Bar */

.investProgressBarDiv {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-top: 15px;
}

.investProgressBarLabel {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  text-align: center;
}

.investProgressBarSubLabel {
  font-size: 12px;
  margin-top: 0px;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  line-height: 12px;
}

.investProgressBarLabelLeft {
  padding-right: 20px;
}

.investProgressBarLabelRight {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .investProgressBarLabelRight {
    padding-left: 0;
  }
}

.investProgressBarBorder {
  width: 60%;
  height: 10px;
  background-color: #d6d6d6;
  border-radius: 50px;
}

.investProgressBar {
  width: 60%;
  height: 10px;
  background-color: #7034c6;
  border-radius: 50px;
}

/* Invest Form Section */

.investFormSection {
  padding: 0 30px;
  height: 100%;
}

@media (max-width: 768px) {
  .investFormSection {
    padding: 0 15px !important;
    /* overflow-y: scroll !important; */
  }
}

.investFormHeaderDiv {
  display: flex;
  padding-bottom: 30px;
}

.investFormHeader {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  font-size: 22px;
}

.investFormPageDiv {
  flex-grow: 1;
}

.investFormPage {
  width: 78px;
  height: 25px;
  background: #535b64;
  color: #ffffff;
  border-radius: 200px;
  text-align: center;
  margin-right: 0;
  margin-left: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 2px;
}

.investFormModel {
  padding: 0 0 45px 0;
  height: 100% !important;
}

.formSubHeader {
  font-size: 18px;
}

.subscriptionDiv {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
}

.iframeWidgetButton,
.iframeWidgetButton:focus,
.iframeWidgetButton:active {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: 100%;
  padding: 15px 0 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600 !important;
  transition-duration: 0.4s !important;
  margin-right: 0;
  margin-left: auto;
  display: block !important;
}

@media (min-width: 768px) {
  .iframeWidgetButton:hover {
    background-color: transparent !important;
    color: #7034c6 !important;
    transition-duration: 0.4s;
  }
}

.iframeWidgetButton:disabled {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}

.investorCompleteSubscrInstr {
  font-size: 20px;
}

.investorCompleteSubscrInstrResendLink {
  text-decoration: none !important;
  color: #7034c6 !important;
  font-size: 20px;
  font-weight: bold;
}

.completeImg {
  width: 100px;
  height: auto;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.investorCompleteText {
  text-align: center;
  padding: 30px 0;
}

@media (min-width: 768px) {
  .investorCompleteText {
    width: 75%;
    margin: auto;
  }
}

.congratulationsHeader {
  text-align: center;
  color: #7034c6;
  margin-top: 30px;
  margin-bottom: 0;
}

.doneButton {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: auto !important;
  padding: 10px 60px !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  transition-duration: 0.4s;
  margin: 60px auto auto auto !important;
  display: block !important;
}

.doneButton:hover {
  background-color: transparent !important;
  color: #7034c6 !important;
  transition-duration: 0.4s;
}

.downloadLink {
  color: #7034c6 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-decoration: none;
  margin-left: 35px;
}

.hyperLink {
  color: #7034c6 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-decoration: none;
}

/* Alert */

.formAlert.alert-warning {
  text-align: center !important;
  color: #ffffff !important;
  /* background-color: rgba(112, 52, 198, 0.5) !important; */
  background-color: rgba(208, 42, 72, 1) !important;
  margin-bottom: 30px !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.formAlert.alert-success {
  text-align: center !important;
  color: #ffffff !important;
  /* background-color: rgba(112, 52, 198, 0.5) !important; */
  background-color: #208f24 !important;
  margin-bottom: 30px !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.notAllowedText {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

.notAllowedBanner {
  padding: 15px 30px;
  background-color: red;
  border: 1px solid red;
  border-radius: 5px;
  margin-bottom: 15px;
}

.notAllowedBannerText {
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  text-align: left;
}

/* Accredited Text */

.accreditedModalBody {
  height: 530px;
}

.accreditedDiv {
  overflow-y: auto;
  height: 500px;
  padding-bottom: 60px;
}

.accreditedHeader {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-align: center;
}

.accreditedText {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

/* Mobile Section */

@media (min-width: 768px) {
  .inputFormButtonColMobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .inputFormButtonColDesktop {
    display: none;
  }
}

.tooltipCustom {
  font-size: 14px !important;
  display: block;
  background-color: rgba(1, 1, 1, 0.8);
  color: #ffffff;
  padding: 15px;
}

#plaidIframe {
  width: 100%;
  height: 720px;
  border: none;
}

#stripeIframe {
  width: 100%;
  height: 420px;
  border: none;
}

#plaidContainer,
#stripeContainer {
  display: none;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0010px rgb(122, 122, 122);
  box-shadow: 0010px rgb(122, 122, 122);
}
