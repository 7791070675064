/* Main */

html {
  scroll-behavior: smooth;
}

body {
  zoom: 1;
  /* overflow-x: hidden; */
  font-family: "Source Sans Pro", sans-serif !important;
}

@media (max-width: 768px) {
  body {
    zoom: 85%;
  }
}

.modal-backdrop.fade {
  width: 100% !important;
  height: 100% !important;
}

.overflowXHidden {
  overflow-x: hidden !important;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

@media (max-width: 768px) {
  .desktopBreakPoint {
    display: none;
  }
}

.displayTable {
  display: table;
}

.displayTableCell {
  display: table-cell;
  vertical-align: middle;
}

.displayNone {
  display: none;
  transition: visibility 0s, opacity 0.5s linear;
}

.displayBlock {
  display: block;
}

.hideBodyScroll {
  height: 100vh !important;
  overflow-y: hidden !important;
}

.blur {
  text-shadow: 0 0 10px #000000;
  color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.hoverDelay {
  transition-duration: 0.4s !important;
}

.brandColor {
  color: #7034c6;
}

.bold {
  font-weight: 700 !important;
}

.heavyBold {
  font-weight: 900;
}

.ivyPrestoItalic {
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  font-style: italic;
}

.ivyPrestoRegular {
  font-family: "ivypresto-display", serif;
  font-weight: 400;
  font-style: normal;
}

.ivyPrestoBold {
  font-family: "ivypresto-display", serif;
  font-weight: 700;
  font-style: normal;
}

.ivyPrestoBoldItalic {
  font-family: "ivypresto-display", serif;
  font-weight: 700;
  font-style: italic;
}

.copperPlate {
  font-family: "Copperplate", sans-serif;
  font-weight: 400 !important;
}

.copperPlateItalic {
  font-family: "Copperplate", sans-serif;
  font-weight: 400 !important;
  font-style: italic;
}

.copperPlateMedium {
  font-family: "Copperplate", sans-serif;
  font-weight: 600 !important;
}

.copperPlateBold {
  font-family: "Copperplate", sans-serif;
  font-weight: 700 !important;
}

.baskerRegular {
  font-family: "Libre Baskerville", serif;
  /* font-family: "Baskervville", serif; */
  font-weight: 400;
}

.baskerItalic {
  font-family: "Libre Baskerville", serif;
  /* font-family: "Baskervville", serif; */
  font-weight: 400;
  font-style: italic;
}

.baskerMedium {
  font-family: "Libre Baskerville", serif;
  /* font-family: "Baskervville", serif; */
  font-weight: 600;
}

.baskerBold {
  font-family: "Libre Baskerville", serif !important;
  /* font-family: "Baskervville", serif; */
  font-weight: 900 !important;
}

@font-face {
  font-family: "Baskervville", sans-serif;
  src: url("../font/Baskervville/Baskervville-Regular.ttf");
}

@font-face {
  font-family: "Libre Baskerville", serif;
  src: url("../font/Libre_Baskerville/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: "Libre Baskerville", serif;
  src: url("../font/Libre_Baskerville/LibreBaskerville-Bold.ttf");
}

@font-face {
  font-family: "Libre Baskerville", serif;
  src: url("../font/Libre_Baskerville/LibreBaskerville-Italic.ttf");
}

.sourceRegular {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
}

.sourceItalic {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400 !important;
  font-style: italic !important;
}

.sourceMedium {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600 !important;
}

.sourceBold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700 !important;
}

@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url("../font/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaRegular.ttf");
}

.georgiaRegular {
  font-family: "Georgia", sans-serif;
  font-weight: 400 !important;
}

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaRegular.ttf");
}

.georgiaItalic {
  font-family: "Georgia", sans-serif;
  font-weight: 400 !important;
  font-style: italic;
}

@font-face {
  font-family: "Georgia", sans-serif;
  src: url("../font/georgia/georgiaBold.ttf");
}

.georgiaBold {
  font-family: "Georgia", sans-serif;
  font-weight: 700 !important;
}

.arial {
  font-family: "Arial", sans-serif;
  font-weight: 400;
}

.generalHeader {
  color: #2b365f;
  font-size: 60px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 1366px) {
  .generalHeader {
    font-size: 36px;
  }
}

.mainContainer {
  padding: 0 140px 0 140px;
}

@media (max-width: 1366px) {
  .mainContainer {
    padding: 0 60px;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    padding: 0 30px;
  }
}

@media (min-width: 1366px) {
  .mainContainerHeight {
    height: 100vh;
  }
}

.headerSpacer {
  padding: 10px;
  display: block;
}

.noStyleBtn {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.generalButton {
  background-color: #ffffff !important;
  color: #7034c6 !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: 100%;
  padding: 15px 0 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  transition-duration: 0.4s;
}

.generalButton:hover {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.generalButtonInverted {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: 100%;
  padding: 15px 0 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  transition-duration: 0.4s;
}

.generalButtonInverted:hover {
  background-color: transparent !important;
  color: #7034c6 !important;
  transition-duration: 0.4s;
}

.generalSubmitButton,
.generalSubmitButton:focus,
.generalSubmitButton:active {
  background-color: #ffffff !important;
  color: #7034c6 !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: 200px;
  padding: 15px 0 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  transition-duration: 0.4s;
  margin-left: 0;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  .generalSubmitButton:hover {
    background-color: #7034c6 !important;
    color: #ffffff !important;
    transition-duration: 0.4s;
  }
}

.generalSubmitButton:disabled {
  cursor: not-allowed !important;
}

.generalSubmitButtonInverted,
.generalSubmitButtonInverted:focus,
.generalSubmitButtonInverted:active {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  border: 1px solid #7034c6 !important;
  border-radius: 0 !important;
  width: 200px;
  padding: 15px 0 !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600 !important;
  transition-duration: 0.4s !important;
  margin-right: 0;
  margin-left: auto;
  display: block !important;
}

@media (min-width: 768px) {
  .generalSubmitButtonInverted:hover {
    background-color: transparent !important;
    color: #7034c6 !important;
    transition-duration: 0.4s;
  }
}

.generalSubmitButtonInverted:disabled {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}

@media (max-width: 768px) {
  .generalSubmitButton,
  .generalSubmitButtonInverted {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .generalButton,
  .generalButtonInverted {
    /* width: 150px !important; */
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .generalButton,
  .generalButtonInverted {
    width: 100% !important;
    font-size: 14px !important;
  }
}

.generalButton:disabled,
.generalButtonInverted:disabled {
  cursor: not-allowed !important;
  pointer-events: all;
  opacity: 0.65;
}

.maxWidth {
  width: 100% !important;
}

.redLinks {
  color: red !important;
  /* color: #7034c6 !important; */
  text-decoration: none !important;
  font-weight: 700 !important;
  font-size: 12px;
}

.generalLink {
  color: #7034c6;
  text-decoration: none !important;
  font-weight: 600;
  transition-duration: 0.4s;
}

.generalLink:hover {
  transition-duration: 0.4s;
}

.generalWhiteLink {
  color: #ffffff !important;
  font-weight: 600;
  transition-duration: 0.4s;
}

.generalWhiteLink:hover {
  transition-duration: 0.4s;
}

/* .generalLink[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
  background: rgba(1, 1, 1, 0.8);
  color: #ffffff;
} */

/* Color */

.invisible {
  opacity: 0 !important;
}

.blue {
  color: #0051f3;
}

.grey {
  color: #b1b1b1;
}

.opaque {
  opacity: 0 !important;
}

.required {
  color: red;
  font-size: 20px;
  font-weight: 900;
}

.fadeInandOut {
  animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Global Styles */

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths,
.col-xl-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 1366px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1650px) {
  .col-xl-5ths {
    width: 20%;
    float: left;
  }
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 77px -17px rgba(0, 0, 0, 0.75);
}

/* Scrollbar Section */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebebeb;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hideDiv {
  animation: FadeAnimation 5s ease-in 0.2s forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.brandHrLine {
  background-color: #7034c6;
}
