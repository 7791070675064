.dealSection {
  padding: 90px 0;
  background-color: #f5f5f6;
}

.dealHeader {
  font-size: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dealLocation {
  font-size: 18px;
  padding-bottom: 30px;
}

.dealStatus {
  padding: 10px 30px;
  color: #ffffff;
  background-color: #d02a48;
  font-size: 12px;
  margin: 0;
  display: block;
}

.dealStatusFunded {
  background-color: #7034c6 !important;
}

@media (min-width: 768px) {
  .dealStatus {
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .dealStatus {
    width: 100%;
    text-align: center;
  }
}

/* Carousel Section */

.dealImageDiv {
  width: 100% !important;
  height: 50em !important;
}

.dealImg {
  width: 100%;
  height: auto;
}

/* .dealCarouselDotClass button {
  background: #7034c6;
} */

/* Deal Info Section */

.dealInfoDiv {
  padding: 0 0 0 30px;
}

@media (max-width: 768px) {
  .dealInfoDiv {
    padding: 0;
  }
}

.dealProgressLabelDiv {
  display: flex;
  width: 100%;
}

.dealProgressLabelRight {
  margin-right: 0;
  margin-left: auto;
}

.dealProgressLabel {
  font-size: 50px;
  line-height: 50px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 900;
  text-align: center;
}

.dealProgressSubLabel {
  font-size: 16px;
  display: block;
  margin-top: -5px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

.dealProgressBarBorder {
  width: 100%;
  height: 10px;
  background-color: #d6d6d6;
  border-radius: 50px;
}

.dealProgressBar {
  height: 10px;
  background-color: #7034c6;
  border-radius: 50px;
}

/* Deal Info Details */

.dealsDetailsRow {
  padding-top: 30px;
}

.dealDetailsCol {
  padding-bottom: 20px;
}

.dealDetailsLabelDiv {
  display: flex;
}

.dealDetailsLabel {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}

.dealDetailsLabelRight {
  text-align: right;
  flex-grow: 1;
}

.dealDetailsSubLabel {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.dealSecFilingsDiv {
  border: 1px dashed #e4d49a;
  padding: 15px;
  background-color: #f5f7e4;
}

.dealSecFilingsHeader {
  margin-bottom: 0;
  text-decoration: underline;
  color: #000000;
}

.dealSecFilingsHeader:hover {
  color: #7034c6;
}

.dealSecFilingsText {
  margin-bottom: 0;
}

.dealDetailsDescText {
  font-size: 20px;
  margin-top: 60px;
}

.dealSummaryDiv {
  padding: 60px 0;
  font-size: 20px;
}

@media (min-width: 768px) {
  .dealSummaryDiv {
    text-align: justify;
  }
}

/* Documents Section */

.filesCol {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .filesDiv {
    display: flex;
    flex-wrap: wrap;
  }
}

.filesDiv {
  margin-bottom: 15px;
}

.filesName {
  margin: 0;
}

.filesLink {
  flex-grow: 1;
  text-align: right;
}

.secDiv {
  display: flex;
  align-items: center;
}

.secLogo {
  width: 100px;
  height: auto;
  margin-top: 15px;
}

/* QA Section */

.askRow {
  padding-top: 90px;
  padding-bottom: 90px;
}

.askButtonDiv {
  width: 100%;
  text-align: right;
}

@media (max-width: 768px) {
  .askButtonDiv {
    text-align: left;
  }
}

.askButton {
  background-color: #ffffff !important;
  color: #7034c6 !important;
  border: 2px solid #7034c6 !important;
  border-radius: 5px !important;
  width: auto !important;
  padding: 10px 60px !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold !important;
  transition-duration: 0.4s;
}

.askButton:hover {
  background-color: #7034c6 !important;
  color: #ffffff !important;
  transition-duration: 0.4s;
}

.answeredByDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.answeredBy {
  font-size: 18px;
  margin-bottom: 0;
}

.answeredByModeratorName {
  margin-bottom: 0;
}

.answeredByModerator {
  background-color: #9f7ad3;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 15px;
  margin-left: 15px;
  margin-bottom: 0;
  width: fit-content;
}

.answeredByMainDiv {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.answeredByAnswer {
  margin-top: 15px;
  font-size: 18px;
  word-wrap: break-word;
}

.commentHeader {
  padding: 15px 0 0 0;
  font-weight: 700;
}

.comment {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 15px;
}

.commentBy {
  color: #000000;
}

.commenter {
  color: #7034c6;
  margin: 0 !important;
  padding: 0 15px;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold !important;
}

.commentDelete {
  color: red;
  margin: 0 !important;
  padding: 0 15px;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold !important;
  text-decoration: none !important;
  display: block;
}

.commentDate {
  color: #000000;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .answeredByDiv {
    display: initial;
  }

  .answeredBy,
  .answeredByAnswer {
    font-size: 18px;
  }

  .answeredByModeratorName {
    margin-left: 0;
    font-size: 18px;
  }

  .answeredByModerator {
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.changeAnswer {
  margin-right: 30px;
}

.deleteAnswer {
  color: red !important;
}

.changeAnswer,
.deleteAnswer {
  font-size: 14px;
}

.dealInvestmentDisabledDisclaimer {
  font-size: 12px;
  font-style: italic;
  padding-top: 15px;
}
