/* .mainRaiseSection {
  background: repeating-linear-gradient(
    141.5deg,
    rgb(245, 245, 246) 0%,
    rgb(245, 245, 246) 34%,
    rgb(265, 265, 265) 34%,
    rgb(265, 265, 265) 102%
  );
} */

.mainRaiseDiv {
  padding-top: 0 !important;
  /* padding-top: 120px !important; */
  padding-bottom: 90px !important;
}

@media (max-width: 768px) {
  .mainRaiseDiv {
    padding-top: 0 !important;
    padding-bottom: 60px !important;
  }
}

.mainRaiseText {
  font-size: 30px;
  line-height: 36px;
}

.mainRaiseUl {
  list-style: none; /* Remove default bullets */
  margin-top: 30px;
}

.mainRaiseLi {
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
}

@media (max-width: 768px) {
  .mainRaiseText {
    font-size: 18px;
    line-height: 26px;
  }
  .mainRaiseLi {
    font-size: 18px;
  }
}

.mainRaiseUl .mainRaiseLi::before {
  /* content: "\2022";  Add content: \2022 is the CSS Code/unicode for a bullet */
  content: url("./img/bullet.svg"); /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  margin-right: 1em;
}

.mainRaiseBtnRow {
  padding-top: 60px;
}

.mainRaiseCol2 {
  text-align: right;
}

@media (min-width: 768px) {
  .mainRaiseCol2Mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .mainRaiseCol2Desktop {
    display: none;
  }
}

.mainRaiseImg {
  width: 90%;
  height: auto;
}

@media (max-width: 768px) {
  .mainRaiseImg {
    padding-bottom: 30px;
    margin-top: -30px;
    width: 100%;
  }
}

/* How it works */

.raiseHowSection {
  background: repeating-linear-gradient(
    50deg,
    rgb(56, 56, 56) 0%,
    rgb(56, 56, 56) 27%,
    rgb(43, 43, 43) 27%,
    rgb(43, 43, 43) 92%
  );
}

.raiseHowDiv {
  padding-top: 90px !important;
  padding-bottom: 60px !important;
}

@media (max-width: 768px) {
  .raiseHowDiv {
    padding-top: 60px !important;
    padding-bottom: 15px !important;
  }
}

.raiseHowRow {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .raiseHowRow {
    margin-bottom: 30px;
  }
}

.raiseHowNumber {
  color: rgba(134, 134, 134, 0.29);
  font-size: 80px;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 900 !important;
  margin-bottom: 0;
  line-height: 80px;
}

@media (max-width: 768px) {
  .raiseHowNumber {
    font-size: 42px;
  }
}

.raiseHowStepsTextDiv {
  color: #ffffff;
}

.raiseHowStepsTextHeader {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .raiseHowStepsTextHeader {
    font-size: 24px;
  }
}

.raiseHowStepsText {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 768px) {
  .raiseHowItWorksButton {
    margin-bottom: 30px;
  }
}

/* Thank You */

.detailsThankYouDiv {
  margin: auto;
  width: 75%;
}

.detialsCheckMark {
  display: block;
  margin: auto auto 30px auto;
}

.detailsThankYouHeader {
  color: #7034c6;
  text-align: center;
  font-size: 40px;
}

.detailsThankYouSubHeader {
  font-size: 20px;
  text-align: center;
  font-weight: 600 !important;
}

.detailsThankYouText {
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}
